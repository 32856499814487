<script lang="ts">
import { templateRef } from '@vueuse/core'
import gsap from 'gsap'
import { ComponentPublicInstance, defineComponent, Ref, ref, watch } from 'vue'

import DelleLogo from '@/assets/svg/logodelle.svg'
import GalleriaLogo from '@/assets/svg/logogalleria.svg'
import MarcheLogo from '@/assets/svg/logomarche.svg'
import NazionaleLogo from '@/assets/svg/logonazionale.svg'

export type PreloaderPublicInstance = ComponentPublicInstance & {
  visible: boolean,
  hide: boolean,
}

export default defineComponent({
  name: 'ThePreloader',
  components: {
    GalleriaLogo,
    NazionaleLogo,
    DelleLogo,
    MarcheLogo
  },
  setup (props, { expose }) {
    const publicInstance = {
      visible: ref(true),
      hide: ref(false)
    }

    const galleria: Ref<ComponentPublicInstance> = templateRef('galleria')
    const nazionale: Ref<ComponentPublicInstance> = templateRef('nazionale')
    const delle: Ref<ComponentPublicInstance> = templateRef('delle')
    const marche: Ref<ComponentPublicInstance> = templateRef('marche')

    const timeline = gsap.timeline({ paused: true, onComplete: () => (publicInstance.visible.value = false) })

    expose(publicInstance)

    watch([galleria, nazionale, delle, marche], ([g, n, d, m]) => {
      if (!g || !n || !d || !m) return
      timeline.to(g.$el, { yPercent: 100, duration: 0.5, ease: 'Power3.easeOut' })
      timeline.to(n.$el, { yPercent: 100, duration: 0.5, ease: 'Power3.easeOut' }, '<')
      timeline.to(d.$el, { yPercent: 100, duration: 0.5, ease: 'Power3.easeOut' }, '<')
      timeline.to(m.$el, { yPercent: 100, duration: 0.5, ease: 'Power3.easeOut' }, '<')
    }, { immediate: true })

    watch(publicInstance.hide, (hide) => {
      if (hide) timeline.play()
    }, { immediate: true })

    return publicInstance
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition appear mode="out-in">
      <div v-if="visible" id="app-loading">
        <!-- <LogoGNDM class="logo c-dark" /> -->
        <div class="logo" :style="{display: 'flex', flexDirection: 'column', alignItems: 'self-start', overflow: 'hidden'}">
          <span class="block logo-part overflow-hidden">
            <GalleriaLogo ref="galleria" class="logo-part-svg h-full" />
          </span>
          <span class="block logo-part overflow-hidden">
            <NazionaleLogo ref="nazionale" class="logo-part-svg h-full" />
          </span>
          <span class="block logo-part overflow-hidden">
            <DelleLogo ref="delle" class="logo-part-svg h-full" />
          </span>
          <span class="block logo-part overflow-hidden">
            <MarcheLogo ref="marche" class="logo-part-svg h-full" />
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: absolute;
  z-index: $z-loader;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $c-azure;
}

.logo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-part {
  overflow: hidden;
  height: rem(10px);
  margin: rem(6px) 0;

  @screen md {
    height: rem(14px);
    margin: rem(8px) 0;
  }
}
</style>
